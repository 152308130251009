
.HomeCard {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.content {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.title {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.arrow {
    width: 20px;
    height: 20px;
    background-image: var(--appearance-arrow);
}
