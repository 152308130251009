
.App {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App ::-webkit-scrollbar {
    display: none;
}

.Routes {
    min-height: calc(100vh - 48px - env(safe-area-inset-bottom));
}

.content {
    min-height: calc(100vh - 48px - env(safe-area-inset-bottom));
    padding-bottom: calc(48px + env(safe-area-inset-bottom));
}

.wallContainer {
    min-height: calc(100vh - env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
}

.routeContainer {
    position: relative;
    min-height: calc(100vh - 48px - env(safe-area-inset-bottom));
    overflow: hidden;
    box-sizing: border-box;
}

.App select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
}

.noPadding {
    padding: 0px !important;
}

.routeContent {
    display: none;
}

.routeContent.visible {
    display: block;
}

:global(.home-text-main) {
    color: var(--appearance-home-text-main, transparent);
    transition: 0.7s color;
}

:global(.home-text-secondary) {
    color: var(--appearance-home-text-secondary, transparent);
    transition: 0.7s color;
}

:global(.home-text-accent) {
    color: var(--appearance-home-text-accent, transparent);
    transition: 0.7s color;
}

:global(.home-text-good) {
    color: var(--appearance-home-text-good, transparent);
    transition: 0.7s color;
}

:global(.home-text-bad) {
    color: var(--appearance-home-text-bad, transparent);
    transition: 0.7s color;
}

:global(.home-card-background) {
    background-color: var(--appearance-home-card-background, transparent);
    transition: 0.7s background-color;
}

:global(.home-card-border) {
    border-color: var(--appearance-home-card-border, transparent);
    transition: 0.7s border-color;
}

:global(.home-border-list) {
    border-color: var(--appearance-home-border-list, transparent);
    transition: 0.7s border-color;
}

:global(.background) {
    background-color: var(--appearance-background, transparent);
    transition: 0.7s background-color;
}

:global(.background-blur) {
    background-color: var(--appearance-background-blur, transparent);
    transition: 0.7s background-color;
}

:global(.background-blur-b) {
    background-color: var(--appearance-background-blur-b, transparent);
    transition: 0.7s background-color;
}

:global(.b1) {
    background-color: var(--appearance-b1, transparent);
    transition: 0.7s background-color;
}

:global(.b2) {
    background-color: var(--appearance-b2, transparent);
    transition: 0.7s background-color;
}

:global(.h1) {
    color: var(--appearance-h1, transparent);
    transition: 0.7s color;

}

:global(.h15) {
    color: var(--appearance-h15, transparent);
    transition: 0.7s color;
}

:global(.h2) {
    color: var(--appearance-h2, transparent);
    transition: 0.7s color;
}

:global(.h3) {
    color: var(--appearance-h3, transparent);
    transition: 0.7s color;
}

:global(.i1) {
    color: var(--appearance-i1, transparent);
    border: var(--appearance-i1-border, 2px solid transparent);
    transition: 0.7s color, 0.7s border;
}

:global(.i1:focus) {
    border: var(--appearance-i1-border-focus, 2px solid transparent);
}

:global(.stat-background-a) {
    background-color: var(--appearance-stat-background-a, transparent);
    transition: 0.7s background-color;
}

:global(.stat-background-b) {
    background-color: var(--appearance-stat-background-b, transparent);
    transition: 0.7s background-color;
}

:global(.stat-background-c) {
    background-color: var(--appearance-stat-background-c, transparent);
    transition: 0.7s background-color;
}
:global(.stat-background-d) {
    background-color: var(--appearance-stat-background-d, transparent);
    transition: 0.7s background-color;
}

:global(.stat-text-a) {
    color: var(--appearance-stat-text-a, transparent);
    transition: 0.7s color;
}

:global(.stat-text-b) {
    color: var(--appearance-stat-text-b, transparent);
    transition: 0.7s color;
}

:global(.stat-text-c) {
    color: var(--appearance-stat-text-c, transparent);
    transition: 0.7s color;
}

:global(.stat-text-d) {
    color: var(--appearance-stat-text-d, transparent);
    transition: 0.7s color;
}


:global(.stat-subtext-a) {
    color: var(--appearance-stat-subtext-a, transparent);
    transition: 0.7s color;
}

:global(.stat-subtext-b) {
    color: var(--appearance-stat-subtext-b, transparent);
    transition: 0.7s color;
}

:global(.stat-subtext-c) {
    color: var(--appearance-stat-subtext-c, transparent);
    transition: 0.7s color;
}

:global(.stat-subtext-d) {
    color: var(--appearance-stat-subtext-d, transparent);
    transition: 0.7s color;
}

:global(.wheel-text) {
    color: var(--appearance-wheel-text, transparent);
    transition: 0.7s color;
}

:global(.wheel-bg) {
    stroke: var(--appearance-wheel-bg, transparent);
    transition: 0.7s stroke;
}

:global(.wheel-fill) {
    stroke: var(--appearance-wheel-fill, transparent);
    transition: 0.7s stroke;
}


:global(.button) {
    width: 100%;
    height: 48px;
    margin: 0px 8px;
    margin-right: 0px;
    padding: 0px 24px;
    /* font-family: 'Poppins', sans-serif; */
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgba(103, 202, 163, 1.0);
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

:global(.cta) {
    height: 48px;
    margin: 0px 8px;
    padding: 0px 24px;
    /* font-family: 'Poppins', sans-serif; */
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgba(103, 202, 163, 1.0);
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

:global(.disabled) {
    pointer-events: none;
    opacity: 0.5;
}

:global(.cancel) {
    width: 100%;
    height: 48px;
    margin: 0px 8px;
    margin-left: 0px;
    padding: 0px 24px;
    /* font-family: 'Poppins', sans-serif; */
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: var(--appearance-button-text);
    text-align: center;
    /* background: rgba(51, 51, 51, 0.3); */
    background-color: var(--appearance-button-bg);
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s opacity ease;
}


:global(.button:hover) {
    opacity: 0.8;
}

:global(.cancel:hover) {
    opacity: 0.8;
}

:global(.cta:hover) {
    opacity: 0.8;
}