
.HomeDataPoint {

}

.value {

}

.info {
    margin-top: 14px;
    display: flex;
}

.value {
    margin-top: 5px;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.7px;
    margin-right: 6px;
}

.highlight {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-right: 6px;
}

.secondary {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
}
