
.content {
    padding-left: 16px;
    padding-right: 16px;
}

.getStartedTip {
    margin-top: 48px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: var(--appearance-h2, transparent);
}

.reload {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 700;
    line-height: 50px;
    color: var(--appearance-h3, transparent);
    width: 100%;
    height: 50px;
    text-align: center;
}

.leaderboards { display: none; }
.spotlight { display: none; }
.globals { display: none; }
.requests { display: none; }
.analytics { display:none; }
.visible {
    display: block;
}
