
.HomeLeaderboard {

}

.item {
    display: flex;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 22px;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.info {
    flex-grow: 1;
}

.name {
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.1px;
}

.rank {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
}

.points {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.2px;
}

.number {
    display: block;
    width: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 22px;
    font-weight: 500;
}

.photo {
    margin-right: 14px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 3px solid transparent;
    box-sizing: border-box;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.1);
}

.tip {
    display: flex;
    align-items: center;
    padding: 18px 2px;
    padding-bottom: 2px;
    box-sizing: border-box;
}

.tipText {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 24px;
}

.tipIcon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 16px;
}