
.content {
    padding-left: 16px;
    padding-right: 16px;
}

.greeting {
    margin-top: 16px;
    margin-bottom: 32px;
}

.greetingTop {
    display: flex;
    justify-content: space-between;
}

.greetingIcon {
    margin-bottom: 20px;
    width: 60px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.0);
    border-radius: 16px;
}

.greetingButton {
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
}

.greetingText {
    font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: -1.25px;
    max-width: 280px;
}

.groupHalf {
    margin-top: 16px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 18px 18px;
}

.groupFull {
    margin-top: 18px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px 16px;
}

.title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.subtitle {
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    opacity: 0.8;
}

.share {
    display: inline-block;
    height: 40px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.description {
    font-size: 17px;
    font-weight: 600;
}

.highlightWheel {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 48px;
}

.wheel {
    position: relative;
    width: 200px;
    height: 200px;
}

.wheelText {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    text-align: center;
    line-height: 180px;
    font-size: 24px;
    font-weight: 900;
    opacity: 0.0;
    transition: opacity 0.7s 0.4s;
    /* transition: opacity 0.7s 0.9s; */
}

.wheelText.visible {
    opacity: 1.0;
}

.wheelBadge {
    position: absolute;
    top: 142px;
    right: 36px;
    width: 72px;
    height: 72px;
    background-size: 72px 72px;
    opacity: 0.0;
    transform: scale(0.8);
    transition: opacity 0.5s 3.2s, transform 0.5s 3.2s;
}

.wheelBadge.visible {
    opacity: 1.0;
    transform: scale(1.0);
}

.giftBox {
    position: absolute;
    right: -12px;
    bottom: 32px;
    width: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 20 20' height='36px' viewBox='0 0 20 20' width='36px' fill='%23F9C57D'%3E%3Crect fill='none' height='20' width='20'/%3E%3Cpath d='M9,6.88L9.85,9.5h2.86l-2.38,1.89l0.8,2.58L9,12.36l-2.12,1.61l0.8-2.58L5.3,9.5h2.86L9,6.88z M7.06,8H1l4.94,3.93L4.06,18 L9,14.25L13.94,18l-1.88-6.07L17,8h-6.06L9,2L7.06,8z M11.36,4.44l0.67,2.06h1.58L12.15,2L11.36,4.44z M16.9,10l-3.1,2.47l1.23,3.97 L17.08,18l-1.55-5l3.77-3H16.9z'/%3E%3C/svg%3E");
    /* background-color: darkgray; */
    opacity: 0.0;
    /* transition: opacity 0.4s 3.2s, transform 0.5s 3.2s ease; */
    transition: opacity 0.4s 0.9s, transform 0.5s 0.9s ease;
    transform: scale(0.7);
    pointer-events: none;
}

.giftBox.visible {
    pointer-events: auto;
    opacity: 1.0;
    transform: scale(1.0);
}






.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.lightSwitch {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    text-align: center;
    padding-top: 6px;
    box-sizing: border-box;
    cursor: pointer;
}

.tips {
    display: flex;
    flex-direction: column;
    opacity: 0.0;
    /* transition: opacity 1.2s 2.4s; */
    transition: opacity 1.2s 0.8s;
}

.tips.visible {
    opacity: 1.0;
}

.stats {
    margin-top: 20px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 20px 20px;
}

.box {
    border-radius: 16px;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.0;
    transform: scale(0.8);
    transition: opacity 0.5s, transform 0.5s;
}

.stats.visible .box {
    transform: scale(1.0);
    opacity: 1.0;
}

.stat {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    margin-bottom: 8px;
}

.labelContainer {
    display: flex;
    justify-content: space-between;
}

.label {
    margin-top: 8px;
    margin-right: 8px;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 2px;
}

.number {
    margin-right: 8px;
    font-size: 24px;
    font-weight: 700;
}

.metric {
    margin-right: 8px;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 2px;
}

.box.a { transition: opacity 0.5s 1.2s, transform 0.5s 1.2s; }
.box.b { transition: opacity 0.5s 1.25s, transform 0.5s 1.25s; }
.box.c { transition: opacity 0.5s 1.3s, transform 0.5s 1.3s; }
.box.d { transition: opacity 0.5s 1.35s, transform 0.5s 1.35s; }
.box.f { transition: opacity 0.5s 1.4s, transform 0.5s 1.4s; }
/* replaced 3. with 1. */

.box .icon {
    color: rgba(255, 255, 255, 0.9);
    margin-left: 4px;
    margin-bottom: 12px;
}

.box .title {
    font-size: 22px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}

.box .description {
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
}

.debugInfo {
    position: absolute;
    top: 64px;
    left: 0px;
    width: 320px;
    height: 620px;
}
